import styled, { ThemeProvider } from "styled-components";
import GothamRounded from "./fonts/fonts";
import GlobalStyle from "./styles/globalStyle";
import theme from "./styles/theme";
import { accentColor, textColor } from "./styles/theme";

const Main = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 500;
  // padding: 2rem 0 1.6rem 0;
  margin: 0;
  color: ${accentColor};
  position: sticky;
  top: 0;
  height: 5rem;
  z-index: 1;
  background-color: #fff;
`;

const ContentContainer = styled.div`
  max-width: 120rem;
  width: 100%;
`;

const SectionContainer = styled.div`
  position: relative;
  flex-direction: column;
  padding: 0 0.4rem 1rem 0.4rem;
  width: 100%;
`;

const SectionHeader = styled.h3`
  padding: 1.2rem 0rem 0rem 0rem;
  margin: 0;
  font-size: 1.2rem;
  color: ${textColor};
`;

const ContentBox = styled.div`
  display: flex;
  width: 100%;
  border-radius: 2rem;
  background-color: #fff;
  margin-bottom: 1rem;

  flex-direction: column;

  @media (min-width: 1280px) {
    margin-bottom: 4rem;
  }
`;

const ContentWrapper = styled.div`
  padding: 0rem 6rem 5rem 6rem;
  @media (max-width: 800px) {
    padding: 0rem 0rem 1rem 0rem;
  }
`;

const ParagraphContent = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 120%;
  font-weight: 300;
  color: ${textColor};
`;

const ParagraphDivContent = styled.div`
  font-size: 1.2rem;
  line-height: 120%;
  font-weight: 300;
  color: ${textColor};
`;

const ListItemContent = styled.li`
  font-size: 1.2rem;
  line-height: 120%;
  color: ${textColor};
  font-weight: 300;
  padding-top: 0.2rem;
`;

const Highlighter = styled.span`
  font-weight: 500;
`;

const Underliner = styled.span`
  text-decoration: underline;
  text-decoration-color: ${textColor};
  color: ${textColor};
`;

const UnorderedList = styled.ul`
  padding-left: 2.4rem;
  @media (min-width: 768px) {
    padding-left: 4rem;
  }
`;

const UnorderedListDash = styled.ul`
  list-style: none;
  padding-left: 2.4rem;
  @media (min-width: 768px) {
    padding-left: 4rem;
  }
  & > li:before {
    display: inline-block;
    content: "-";
    margin-left: -2rem;
    margin-right: 1rem;
  }
`;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GothamRounded />
      <GlobalStyle />
      <Main>
        <Title>Privacy Policy</Title>
        <ContentContainer>
          <SectionContainer>
            <ContentBox>
              <ContentWrapper>
                <ParagraphContent>
                  2GO Express, Inc., and 2GO Group, Inc. (including affiliates and subsidiaries) hereinafter
                  collectively referred to as “2GO” , “We” , “Us” or “Our” as the context may require, are committed to
                  protect your personal data and respect your personal data privacy in accordance with the Data Privacy
                  Act of 2012 and the issuances of the National Privacy Commission or NPC.
                </ParagraphContent>

                <SectionHeader>WHY WE PROCESS YOUR DATA</SectionHeader>
                <ParagraphContent>
                  We will collect, store, and otherwise process, your personal data for the purposes of facilitating the
                  services you initiated via the use of the 2GO Mobile App Philippines (“App”) and addressing,
                  resolving, and processing any concerns or inquiries you may have related to the App or the services
                  you will avail; improving products, services, and customer experience; and complying with legal,
                  government and regulatory requirements (collectively “Purposes”). If it has been identified that there
                  is a need for Us to make decisions about you without human intervention (i.e., through ADM or
                  automated decision-making), you will be properly notified thereof and your consent will be taken prior
                  to said automated processing
                </ParagraphContent>

                <SectionHeader>DATA WE PROCESS</SectionHeader>
                <ParagraphContent>
                  The following personal data (collectively “Data”) will be processed when you use the App:
                </ParagraphContent>

                <ParagraphDivContent>
                  <UnorderedListDash>
                    <ListItemContent>
                      <Underliner>When registering for the App</Underliner>: your name, email address, mobile number,
                      and profile photo. You have the option to create a profile using your log-in credentials from
                      third-party applications or accounts such as but not limited to Google, Facebook, and Apple ID. In
                      such cases, the owners of these third-party applications may share with us your data such as your
                      name, email address, user ID and profile photo. The sharing and processing of these personal data
                      shall be subject to the terms of use & privacy policy of the respective applications, including
                      2GO Mobile App.
                    </ListItemContent>
                    <ListItemContent>
                      <Underliner>For parcel delivery service</Underliner>: the shipping details such as account number
                      (if applicable), name, email, address, mobile phone of the shipper and consignee.
                    </ListItemContent>
                    <ListItemContent>
                      <Underliner>For 2GO Travel ticket bookings</Underliner>: the name, address, contact number, email
                      address, age, and gender of the person booking the ticket and/or passengers as may be applicable
                    </ListItemContent>


                    <ListItemContent>
                      <Underliner>When using the service of <b>Paynamics Technologies, Inc. (Paynamics)</b> to pay for your booking</Underliner>: your first name last name, mobile number, and email address will be shared to Paynamics. All Data received by, and in the custody of, Paynamics are covered by the <a href="https://www.paynamics.com/terms-of-service">Paynamics - Terms of Service</a> and the{" "}
                      <span>
                        <a href="https://www.paynamics.com/privacy-policy">Paynamics - Privacy Policy</a>
                      </span>. For questions on how Paynamics use your data, you may send an email to compliance@paynnamics.net.
                    </ListItemContent>


                    <ListItemContent>
                      Your device’s location will be collected for certain services (such as, but not limited to, branch
                      locator and FedEx shipments).
                    </ListItemContent>
                    <ListItemContent>
                      The App will request access to your device’s address book if you wish to auto-populate information
                      from your address book; camera if you prefer to scan the tracking number instead of manually
                      typing it when you wish to track a delivery; and gallery for saving images of successful bookings
                      and/or itinerary. You may remove the permissions anytime from your device’s settings.
                    </ListItemContent>
                    <ListItemContent>
                      When you delete your account in the App’s Profile settings, all your personally identifiable Data
                      will no longer be accessible to Us.
                    </ListItemContent>
                    <ListItemContent>
                      We will use Google Analytics to collect anonymous information that will help us understand how
                      users interact with the App.
                    </ListItemContent>
                  </UnorderedListDash>
                </ParagraphDivContent>
                <ParagraphContent>
                  When providing Us Data of individuals other than yourself, you are responsible for notifying them
                  about this Privacy Policy.
                </ParagraphContent>

                <ParagraphContent>
                  We will retain your personal data for as long as your account remains active and for as long as there
                  is legal basis to continue processing your personal data. We reserve the right to retain Data for a
                  period necessary to comply with legal or regulatory obligations (i.e., 10 years for tax compliance),
                  court orders or processes, resolution of disputes, prevention of fraud or abuse, enforcement of our
                  terms and conditions or, to meet our contractual obligations to third parties. Data beyond the
                  retention period are safely destroyed or deleted from our systems. Information that can no longer
                  identify an individual are stored indefinitely and are analyzed to improve our products and services
                  and enhance customer experience.
                </ParagraphContent>

                <SectionHeader>HOW WE SECURE YOUR DATA</SectionHeader>
                <ParagraphContent>
                  There are always risks involved when processing Data whether on paper, in storage, or in transit.
                  Examples of risks include data loss, unauthorized access and/or disclosure, alteration, or others that
                  may be accidental or intentional as in the case of hacking and the like. While risks cannot be totally
                  prevented, 2GO has in place organizational, physical, and technological security measures to mitigate
                  these risks. Security measures include, among others, ensuring that paper records are stored in secure
                  office and warehouse facilities, ensuring that access to data is under strict confidentiality,
                  implementing a system access management policy, having firewall, encrypting data in transit, and
                  scanning systems for vulnerabilities. Automated access such as Application Programming Interface
                  (APIs) and Single Sign-in are secured via encryption and Secure Sockets Layer (SSL).
                </ParagraphContent>

                <SectionHeader>ACCESS TO YOUR DATA</SectionHeader>
                <ParagraphContent>
                  Access to data will only be allowed for authorized personnel of parent, affiliate, or subsidiary
                  companies; vendors; our third-party service providers; and agents for the purposes indicated in this
                  Privacy Policy
                </ParagraphContent>
                <ParagraphContent>
                  We may disclose your Data when required by law or court order, or as requested by government or law
                  enforcement authorities, or in good faith that disclosure is otherwise necessary or advisable
                  including and without limitation to protect the rights or properties of 2GO. This also applies when we
                  have reason to believe that disclosing your Data is necessary to identify, contact or bring legal
                  action against someone who may be causing interference with 2GO’s rights or properties, whether
                  intentionally or otherwise, or when anyone else could be harmed by such activities.
                </ParagraphContent>

                <SectionHeader>YOUR RIGHTS AS A DATA SUBJECT</SectionHeader>
                <ParagraphContent>Your rights under the Data Privacy Act include:</ParagraphContent>
                <ParagraphContent>
                  <UnorderedList>
                    <ListItemContent>
                      the right to be informed that your data is being, will be, or has been processed
                    </ListItemContent>
                    <ListItemContent>the right to reasonable access to your data</ListItemContent>
                    <ListItemContent>
                      the right to request the correction of your Data as long as the request is not vexatious or
                      otherwise unreasonable
                    </ListItemContent>
                    <ListItemContent>
                      the right to request the erasure of your Data upon discovery and with substantial proof that the
                      Data is incomplete, outdated, false, unlawfully obtained, used for unauthorized purposes, or are
                      no longer necessary for the purposes for which it was collected
                    </ListItemContent>
                    <ListItemContent>
                      the right to be indemnified for damages sustained for the unauthorized use of your Data
                    </ListItemContent>
                    <ListItemContent>
                      the right to lodge a complaint to the Data Protection Officer or the NPC
                    </ListItemContent>
                    <ListItemContent>the right to request copies of your Data</ListItemContent>
                  </UnorderedList>
                </ParagraphContent>

                <ParagraphContent>
                  Objections to the processing of your Data or requests for correction, deletion, or blocking of Data
                  shall be subject to the provisions of applicable laws and 2GO’s internal policies, processes, and
                  procedures. We may charge a reasonable fee to cover the administration costs of processing requests.
                </ParagraphContent>

                <SectionHeader>INQUIRIES AND EXERCISING YOUR RIGHTS</SectionHeader>
                <ParagraphContent>
                  If you wish to exercise any of your rights, or if you have questions on how your Data is processed,
                  please send an email to the Data Protection Office at{" "}
                  <a href="mailto:dataprivacy@2go.com.ph">dataprivacy@2go.com.ph</a>.
                </ParagraphContent>

                <SectionHeader>MINORS</SectionHeader>
                <ParagraphContent>
                  This App is <Highlighter>NOT</Highlighter> intended for persons who are below eighteen (18) years old
                  (“Minors”). We neither offer products or services to, nor knowingly collect personal data of, Minors
                  without legal basis. Should we learn that we were provided with personal data of Minors for reasons
                  that are not in accordance with the Data Privacy Act, we will delete the same from our database unless
                  there is legal basis for retaining and processing such personal data (e.g. contract entered into
                  and/or consent given by the parent or legal guardian of the Minors on their behalf).{" "}
                  <Highlighter>
                    If you are a Minor, please do not download the App and do not provide us any Data
                  </Highlighter>
                  .
                </ParagraphContent>

                <SectionHeader>AMENDMENT</SectionHeader>
                <ParagraphContent>
                  2GO may amend this Privacy Policy from time to time by posting an updated version on this App.
                </ParagraphContent>
              </ContentWrapper>
            </ContentBox>
          </SectionContainer>
        </ContentContainer>
      </Main>
    </ThemeProvider>
  );
}

export default App;
